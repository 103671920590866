<template>
  <div class="content">
    <van-empty v-if="hasNodata" image="error" description="暂无报警数据" />
    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="item-content" v-for="(item,index) in list" :key="index">
        <div class="item">
          <div>
            <label style="color: #333; font-size: 15px">报警类型：</label>
            <span style="color: #333; font-size: 15px">{{ item.content }}</span>
          </div>
          <div style="margin-top: 6px">
            <label style="color: #333; font-size: 15px">报警值：</label>
            <span style="color: #333; font-size: 15px">{{ item.value }}</span>
          </div>
          <div style="margin-bottom: 6px; padding-top: 6px">
            <label style="color: #333; font-size: 15px">设备名称：</label>
            <span style="color: #333; font-size: 15px">{{
              item.deviceName
            }}</span>
          </div>
          <div v-if="item.disposed" style="margin-top: 6px; margin-bottom: 6px">
            <label style="color: #333; font-size: 15px">恢复时间：</label>
            <span style="color: #333; font-size: 15px">{{
              item.updatedAt | formatDate
            }}</span>
          </div>
          <div  style="margin-top: 6px; margin-bottom: 6px">
            <label style="color: #333; font-size: 15px">报警时间：</label>
            <span style="color: #333; font-size: 15px">{{
              item.createdAt | formatDate
            }}</span>
          </div>
        </div>
        <div class="tag">
          <van-tag :type="item.disposed?'success':'danger'" size="large">{{
            item.disposed ? '已恢复' : '未恢复'
          }}</van-tag>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      size: 20,
      list: [],
      loading: false,
      finished: false,
      hasNodata: false,
    }
  },
  created() {},
  computed: {},
  methods: {
    onLoad() {
      this.getList()
    },
    getList() {
      this.http
        .get('/gas/exception/list', {
          params: {
            companyId: localStorage.getItem('companyId'),
            page: this.page,
            size: this.size,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.loading = false

            this.list = this.list.concat(res.data.data.list)
            if (!res.data.data.hasNextPage) {
              this.finished = true
            } else {
              this.page++
              this.finished = false
            }
            if (this.page == 1 && this.list.length == 0) {
              this.hasNodata = true
            } else {
              this.hasNodata = false
            }
          }
        })
    },
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
  },
  mounted() {
    // this.getList();
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f2f2f2;
}
.item-content {
  margin-top: 1px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .item {
    flex: 1;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 25px;
  }
  .tag {
    padding-right: 25px;
    padding-top: 10px;
  }
}
</style>
